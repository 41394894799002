
/*>>>>>>>> Team area styling <<<<<<<<<*/

.omga-about__team-area{
  background-color: $l1-primary-background;
  position: relative;
      .shape-1 {
        position: absolute;
        top: 0;
        left: -133px;
      }
      .shape-2 {
        position: absolute;
        top: 0;
        right: -270px;
      }
      .section-title{
        .title{
          color:$l1-primary-heading;
        }
          p{
            color:$l1-primary-text;
          }
      }
      
}
.team-widget-1{
  text-align: center;
  .widget-image{
    max-width: 160px;
    min-width: 160px;
    min-height: 160px;
    max-height: 160px;
    overflow: hidden;
    border-radius: 500px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 29px;
  }
  .widget-text{
    .title{
      color: $l1-primary-text;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -0.75px;
      line-height: 1;
    }
    .subtitle{
      padding-top: 9px;
      color: $l1-third-text;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height:1;
      // text-transform: uppercase;
    }
  }
}