  /*>>>>>>>> CTA area Common styling <<<<<<<<<*/



  /*~~~~CTA Area styling 1 (landing-1)~~~~~*/ 

  .omga-01_{
    &_cta-section {
      background-color: $l1-secondary-background;
      position: relative;
      .shape-1 {
        position: absolute;
        top: 0;
        left: 0px;
      }
      .shape-2 {
        position: absolute;
        top: 0;
        right: 0px;
      }
      .section-title {
        .title {
          color: #FFF;
          margin-bottom: 35px;
        }
        p {
          color: rgba(255, 255, 255, 0.7);
          margin-bottom: 40px;
        }
      }
    }
  }

  .cta-form--1 {
    .form-control {
      border-radius: 10px;
      background-color: #ffffff;
      height: 71px;
      margin-bottom: 15px;
      color: $l1-primary-text;
      font-family: $l1-primary-font;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: -0.56px;
      @include tablet {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    .submit-btn {
      width: 100%;
    }
  }
  
  /*~~~~CTA Area styling 2 (landing-2)~~~~~*/ 

  .omga-02_ {
    &_cta-section {
      background: $l2-secondary-color;
      .section-title {
        .title {
          color: #fff;
          margin-bottom: 30px;
        }
        p {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
  .cta-form--2{
    position: relative;
    margin-top: 45px;
    .input-group{
      max-width: 445px;
      height: 70px;
      border-radius: 10px;
      border: 1px solid #EAE9F2;
      background-color: #FFFFFF;
      position: relative;
      i{
        position: absolute;
        top: 24px;
        left:22px;
        font-size: 20px;
      }
      input{
        padding-left: 63px;
        padding-right: 63px;
        width: 100%;
        border: 0;
        border-radius: 10px;
        color: $l2-primary-text;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: -0.56px;
        text-align: center;
        &:focus{
          box-shadow: none;
          outline: none;
        }
        &::placeholder{
          color: $l2-primary-text;
          font-weight: 400;
          text-align: center;
        }
      }
    }
    button{
      margin-top: 15px;
      max-width: 445px;
      width: 100%;
    }
  }

 /*~~~~CTA Area styling 3 (landing-3)~~~~~*/ 

  .omga-03__cta-section{
    padding-top: 90px;
    padding-bottom: 85px;
    position: relative;
    z-index: 1;
    @include desktops {
      padding-top: 120px;
      padding-bottom: 115px;
    }
    @include large-desktops {
      padding-top: 170px;
      padding-bottom: 165px;
    }
   
    .cta-shape{
      position: absolute;
      &-1{
        display: none;
        @include tablet {
          display: block;
          top: 4%;
          right: -13%;
        }
        @include extra-large-desktops{
          right: -2%;
          right: -2%;
        }
      }
      &-2{
        bottom: 0;
        left: -5%;
      }
    }
  }

 /*~~~~CTA Area styling 4 (landing-4)~~~~~*/ 

  .omga-04__cta-section{
    padding-top: 40px;
    padding-bottom: 50px;
    background-color: $l4-secondary-color;
   
    @include tablet{
      padding-top: 110px;
      padding-bottom: 110px;
    }
    h2{
      color: #fff;
      font-size: 60px;
      line-height: 70px;
      letter-spacing: -2.5px;
    }
  }


 /*~~~~CTA Area styling 6 (landing-6)~~~~~*/ 
 .omga-05_{
  &_cta-section {
    background-color: $l1-primary-heading;
    position: relative;
    padding-top: 40px;
    padding-bottom: 50px;
    @include tablet{
      padding-top: 90px;
      padding-bottom: 118px;
    }
    .section-title {
      .title {
        color: #FFF;
        margin-bottom: 22px;
      }
      p {
        color: $l5-primary-text;
        margin-bottom: 35px;
      }
    }
    .cta-btn{
      padding-top: 5px;
      .btn{
        width: 250px;
        height: 60px;
        border-radius: 10px;
        border: 1px solid $l5-btn-border;
        background-color: $theme-red-color;
        color: #fff;
        font-size: 21px;
        font-weight: 500;
        letter-spacing: -0.66px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        &:hover{
          border-color: #fff;;
        }
      }
      .btn-bottom-text{
        color: rgba(255, 255, 255, 0.7);
        font-size: 14px;
        font-weight: 300;
        letter-spacing: -0.44px;
        padding-top: 10px;
        display: block;
      }
    }
  }
}


 /*~~~~CTA Area styling 6 (landing-6)~~~~~*/ 

    .omga-06__cta-section{
      padding-top: 40px;
      padding-bottom: 35px;
      background-color: #F6F6F8;
      @include tablet{
        padding-top: 95px;
        padding-bottom: 68px;
      }
      h2{
        color: inherit;
      }
      .cta-btn {
        width: 100%;
        @include desktops {
          width: auto;
        }
        .btn--primary {
          width: 100%;
          @include desktops {
            width: 220px;
          }
        }
      }
    }
  
 /*~~~~CTA Area styling 7 (landing-7)~~~~~*/ 

.omga-07__cta-section{
  background:$l7-body-bg;
}

.omga-07__cta-content{
text-align: center;
padding-top: 45px;
padding-bottom: 45px;
@include tablet {
padding-top: 90px;
padding-bottom: 100px;
}
h2 {
color: $l7-primary-heading;
font-size: 50px;
font-weight: 700;
letter-spacing: -1.88px;
line-height: 55px;
margin-bottom: 15px;
@include mobile-lg{
font-size: 60px;
line-height: 70px;
}
}
p{
  color: $l7-primary-text;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}
.cta-btns{
margin-top: 35px;
display: flex;
justify-content: center;
.apple-store {
padding: 0 9px;
transition: .4s;
&:hover {
  transform: translateY(-8px);
}
}
.google-play {
padding: 0 9px;
transition: .4s;
&:hover {
  transform: translateY(-8px);
}
}
}
}

  
 /*~~~~ CTA Area styling 8 (blog-page) ~~~~~*/ 

.omga-08__cta-section {
  background-color: $l1-primary-heading;
  position: relative;
  .shape-1 {
    position: absolute;
    top: 0;
    left: 0px;
  }
  .shape-2 {
    position: absolute;
    top: 0;
    right: -270px;
  }
  .section-title {
    .title {
      color: #FFF;
      margin-bottom: 35px;
    }
    p {
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 40px;
    }
  }
  .cta-btn{
    padding-top: 5px;
    .btn{
      width: 250px;
      height: 60px;
      border-radius: 10px;
      border: 1px solid #c31a12;
      background-color: #f04037;
      color: #fff;
      font-size: 21px;
      font-weight: 500;
      letter-spacing: -0.66px;
      overflow: hidden;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}

 /*~~~~ CTA Area styling 9 (blog-page) ~~~~~*/ 

.omga-09__cta-section {
  background-color: $l1-primary-heading;
  position: relative;
  @include large-desktops{
    padding-top: 150px;
    padding-bottom: 80px;
  }
  .shape-1 {
    position: absolute;
    top: 0;
    left: 0px;
  }
  .shape-2 {
    position: absolute;
    top: 0;
    right: -270px;
  }
  .section-title {
    .title {
      color: #FFF;
      margin-bottom: 35px;
    }
    p {
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 40px;
    }
  }
  .cta-btn{
    padding-top: 5px;
    .btn{
      width: 250px;
      height: 60px;
      border-radius: 10px;
      border: 1px solid #c31a12;
      background-color: #f04037;
      color: #fff;
      font-size: 21px;
      font-weight: 500;
      letter-spacing: -0.66px;
      overflow: hidden;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}
/*~~~~ CTA Area styling 10 (about-page) ~~~~~*/ 

.omga-10__cta-section {
  background-color: $l1-primary-heading;
  position: relative;
  @include large-desktops{
    padding-top: 150px;
    padding-bottom: 80px;
  }
  .shape-1 {
    position: absolute;
    top: 0;
    left: 0px;
  }
  .shape-2 {
    position: absolute;
    top: 0;
    right: -270px;
  }
  .section-title {
    .title {
      color: #FFF;
      margin-bottom: 35px;
    }
    p {
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 40px;
    }
  }
  .cta-btn{
    padding-top: 5px;
    .btn{
      width: 250px;
      height: 60px;
      border-radius: 10px;
      border: 1px solid #c31a12;
      background-color: #f04037;
      color: #fff;
      font-size: 21px;
      font-weight: 500;
      letter-spacing: -0.66px;
      overflow: hidden;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// /*~~~~ CTA Area styling 10 (about-page) ~~~~~*/ 

// .cta-section {
//   background-color: $l1-primary-heading;
//   position: relative;
//   .shape-1 {
//     position: absolute;
//     top: 0;
//     left: 0px;
//   }
//   .shape-2 {
//     position: absolute;
//     top: 0;
//     right: -270px;
//   }
//   .section-title {
//     .title {
//       color: #FFF;
//       margin-bottom: 35px;
//     }
//     p {
//       color: rgba(255, 255, 255, 0.7);
//       margin-bottom: 40px;
//     }
//   }
//   .cta-btn{
//     padding-top: 5px;
//     .btn{
//       width: 250px;
//       height: 60px;
//       border-radius: 10px;
//       border: 1px solid #c31a12;
//       background-color: #f04037;
//       color: #fff;
//       font-size: 21px;
//       font-weight: 500;
//       letter-spacing: -0.66px;
//       overflow: hidden;
//       display: inline-flex;
//       align-items: center;
//       justify-content: center;
//     }
//   }
// }